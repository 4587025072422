
<template>
  <!-- <div class="div">
      <h1>{{width}}</h1> -->
      <slidejs></slidejs>

    





    <!-- <swiper class="swiper" :options="swiperOption">
      <swiper-slide class="test livro">
        <a><img class="img-fluid" src="https://www.evolution.com.br/contents/fullcontent/15187860/coverarts/medium_15187860.jpg"></a>
      </swiper-slide>
      <swiper-slide class="test ">Slide 2</swiper-slide>
      <swiper-slide class="test">Slide 3</swiper-slide>
      <swiper-slide class="test">Slide 4</swiper-slide>
      <swiper-slide class="test">Slide 5</swiper-slide>
      <swiper-slide class="test">Slide 6</swiper-slide>
      <swiper-slide class="test">Slide 7</swiper-slide>
      <swiper-slide class="test">Slide 8</swiper-slide>
      <swiper-slide class="test">Slide 9</swiper-slide>
      <swiper-slide class="test">Slide 10</swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <div class="">
      <ul>
        <li v-for="livro in livros" :key="livro.id" >
          <p>Title: {{livro.resource_metadata.title}} <br>
          ID: {{livro.id}}</p> <br>
        </li>
      </ul>
    </div> -->
  <!-- </div> -->
</template>

<script>
  // import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
  // import '/node_modules/swiper/swiper-bundle.css'
  // import store from '@/store/exlibris.js';
  import slidejs from '@/components/slidejs.vue'


  export default {
    name: 'swiper-example-multiple-slides-per-biew',
    title: 'Multiple slides per view',
    components: {
      // Swiper,
      // SwiperSlide,
      slidejs
    },
    data() {
      return {
        width: null
        // livros: store.livros[0].portfolios.portfolio, // Lista de livros
        // swiperOption: {
        //   slidesPerView: 7.5,
        //   spaceBetween: 30,
        //   pagination: {
        //     el: '.swiper-pagination',
        //     clickable: true
        //   }
        // }
      }
    }
  }
</script>

<style scoped>
  /* .livro {
    transition: all .3s ease-in-out;
  }
  .livro:hover {
      transition: all .3s ease-in-out;
      -ms-transform: scale(1.4);
      -webkit-transform: scale(1.4);  
      transform: scale(1.1);
  }
  .livro img {  
      border-radius: 10px;
      box-shadow: var(--shadow) 0px 3px 8px;
      width: 200px;
      height: max-content;
      margin: 10px;
      max-height: 250px;
      min-width: 150px;
  }
  .livro img:hover {  
      box-shadow: 0px 1px 5px 2px var(--shadow);
  } */
</style>